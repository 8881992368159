<template>
  <div id="dashboard-list">
    <v-row>
      <v-col
        cols="4"
      >
        <v-card style="min-height: 300px;">
          <v-card-title class="align-start">
            <h4>
              取引先
            </h4>
          </v-card-title>
          <v-card-text>
            <div v-for="(customer, i) in customerList" :key="i" :class="`d-flex align-start ${i > 0 ? 'mt-3' : ''}`">
              <!-- <v-row>
                <v-col cols="12">
                  <div>
                    <h4 class="font-weight-medium ml-1">
                      <a
                        href="javascript:void(0);"
                        @click="jumpToCustomerList(customer.cust_id)"
                      >
                        <div style="display: inline-block;">{{ customer.cust_id }}</div>
                        <div style="display: inline-block;padding-left:10px;">{{ customer.email }}</div>
                      </a>
                    </h4>
                  </div>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col cols="6">
                  <div>
                    <h4 class="font-weight-medium ml-1">
                      <a
                        href="javascript:void(0);"
                        @click="jumpToCustomerList(customer.date)"
                      >{{ customer.date }}</a>
                    </h4>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="ms-1">
                    <p class="text--primary font-weight-medium mb-1 text-right">
                      {{ customer.cnt }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card style="min-height: 300px;">
          <v-card-title class="align-start">
            <h4>予約実績</h4>
          </v-card-title>
          <v-card-text>
            <div v-for="(reservation, j) in reservationList" :key="j">
              <v-row>
                <v-col cols="6">
                  <div>
                    <h4 class="font-weight-medium ml-1">
                      <a
                        href="javascript:void(0);"
                        @click="jumpToReservationList(reservation.date)"
                      >{{ reservation.date }}</a>
                    </h4>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="ms-1">
                    <p class="text--primary font-weight-medium mb-1 text-right">
                      {{ reservation.cnt }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card style="min-height: 300px;">
          <v-card-title class="align-start">
            <h4>入札実績</h4>
          </v-card-title>
          <v-card-text>
            <div v-for="(bidding, k) in biddingList" :key="k">
              <v-row>
                <v-col cols="6">
                  <div>
                    <h4 class="font-weight-medium ml-1">
                      <a
                        href="javascript:void(0);"
                        @click="jumpToBiddingList(bidding.date)"
                      >{{ bidding.date }}</a>
                    </h4>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="ms-1">
                    <p class="text--primary font-weight-medium mb-1 text-right">
                      {{ bidding.cnt }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- <v-col cols="6">
        <v-card style="height: 300px;">
          <v-card-title class="align-start">
            <h4>契約</h4>
          </v-card-title>
        </v-card>
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
import {
  mapState, mapMutations, mapActions,
} from 'vuex'

export default {
  components: {
  },
  data: () => ({
    customerList: [],
    reservationList: [],
    biddingList: [],
  }),
  computed: {
    ...mapState('dashboardStore', ['dashboardList']),
  },
  created() {
    this.loadData()
  },
  destroyed() {
  },
  methods: {
    ...mapActions('dashboardStore', ['loadDashboardList']),
    ...mapMutations('app', ['setOverlayStatus']),
    loadData() {
      this.setOverlayStatus(true)
      this.loadDashboardList()
        .then(() => {
          console.log('dashboardList', this.dashboardList)
          this.customerList = this.dashboardList.customers ?? []
          this.reservationList = this.dashboardList.reservations ?? []
          this.biddingList = this.dashboardList.biddings ?? []
          this.setOverlayStatus(false)
        }).catch(() => {
          this.setOverlayStatus(false)
        })
    },
    jumpToCustomerList(date) {
      this.$router.push({
        path: '/customer-list',
        query: {
          startDate: date,
          endDate: date,
        },
      })
    },
    jumpToReservationList(date) {
      this.$router.push({
        path: '/reserved-list',
        query: {
          startDate: date,
          endDate: date,
        },
      })
    },
    jumpToBiddingList(date) {
      this.$router.push({
        path: '/bidding-list',
        query: {
          startDate: date,
          endDate: date,
        },
      })
    },
  },
}
</script>
